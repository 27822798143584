export const features = [
  {
    title: 'Broad age group',
    text: 'Interchangeable arms for pediatric and adult patients',
    img: './../../assets/doctorImages/arms.jpg',
  },
  {
    title: 'Rugged base',
    text: 'The bottom of the device has a rugged texture to avoid undesired displacement on the hospital bed',
    img: './../../assets/doctorImages/bot.jpg',
  },
  {
    title: 'Plastic Wraps',
    text: 'Easy to clean',
    img: './../../assets/doctorImages/clean.jpg',
  },
  {
    title: 'Concurrent Motion',
    text: 'Arms move concurrently. Quick release rail lock mechanism',
    img: './../../assets/doctorImages/front.jpg',
  },
  {
    title: 'Head pad',
    text: 'Layer of comfort between the patient and the device',
    img: './../../assets/doctorImages/pad.jpg',
  },
];
