import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  events: any = [
    {
      title: 'Finalization of SJTD designed in preparation of Clinical Study',
      date: 'Late 2022',
      status: 'R',
    },
    {
      title: 'IRB approved clinical study with Kaiser Permanente',
      date: 'Early - Mid 2023',
      status: 'T',
    },
    {
      title: 'Capital fundraising for manufacturing',
      date: 'Late 2023',
    },
    {
      title: 'Marketing and distribution of SJTD',
      date: '2024',
    },
  ];
  constructor() {}

  ngOnInit() {}
}
