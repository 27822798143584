import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FeaturesComponent } from './features/features.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { TabsPage } from './tabs/tabs.page';
import { TimelineComponent } from './timeline/timeline.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
  },
  {
    path: 'tabs',
    component: TabsPage,
  },
  {
    path: 'features',
    component: FeaturesComponent,
  },
  {
    path: 'timeline',
    component: TimelineComponent,
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
