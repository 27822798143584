import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  deviceDescription =
    'Rune Innovations Inc. is a medical device company that aims to design, patent, and market devices that upend traditional standards of medical practice and care in the emergent and operative settings SJTD: Our inaugural device addresses a well-defined and unanswered need in the operative, post-operative, and monitored anesthesia care (MAC) settings: the clearing of adolescent or adult patient’s obstructed airways by administering and maintaining the jaw thrust maneuver';
  constructor() {}

  ngOnInit() {}
}
