import { Component, OnInit } from '@angular/core';
import { features } from './features';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {
  title1 = 'Broad age group';
  title2 = 'Rugged base';
  title3 = 'Plastic Wraps';
  title4 = 'Concurrent Motion';
  title5 = 'Head pad';
  text1 = 'Interchangeable arms for pediatric and adult patients';
  text2 =
    'The bottom of the device has a rugged texture to avoid undesired displacement on the hospital bed';
  text3 = 'Easy to clean';
  text4 = 'Arms move concurrently. Quick release rail lock mechanism';
  text5 = 'Layer of comfort between the patient and the device';

  features = features;

  list = [
    {
      obj1: {
        title: this.title1,
        text: this.text1,
      },
    },
  ];
  constructor() {}

  ngOnInit() {}
}
